import React, { useState } from 'react'
import cn from 'classnames'
import { RichText, hyphenate } from '@islandsstofa/react'
import { Container } from '@kogk/common'

interface Props {
  tabs: {
    title: string
    content: {
      html: string
    }
  }[]
}

const Tabs = ({ tabs }: Props) => {
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <Container className='page-slice-spacer__smaller p-0'>
      <div className='d-flex col-lg-8 offset-lg-2'>
        {tabs.map((tab, index) => (
          <div className='tab' key={index}>
            <button
              className={cn('tab__header', {
                'tab__header--highlighted': index === tabIndex
              })}
              key={index}
              onClick={() => setTabIndex(index)}
              aria-label={tab.title}
            >
              <span
                className={cn('tab__title', {
                  'tab__title--highlighted': index === tabIndex
                })}
              >
                {index + 1}. {hyphenate(tab.title)}
              </span>
            </button>
          </div>
        ))}
      </div>
      <div className='mt-4'>
        <RichText html={tabs[tabIndex].content.html} />
      </div>
    </Container>
  )
}

export default Tabs
