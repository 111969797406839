import React from 'react'
import {
  SeeAllContainer,
  SeeAllHead,
  SeeAllResultsUi,
  Card
} from '@islandsstofa/react'
import { beautifyDate } from '@islandsstofa/react/src/utils/date'
import { Link } from '@kogk/gatsby-theme-base'
import classNames from 'classnames'
import useGetFutureAndPastEvents from '@root/src/hooks/useGetFutureAndPastEvents'

interface Props {
  pastEventsTitle: string
  futureEventsTitle: string
  noFutureEventsTitle: string
}

const renderEventCard = (card: any) => (
  <Link key={card.url} to={card.url}>
    <Card
      containerClass='card--see-all--three-col mr-2 mb-2'
      card={card}
      image={card.image ?? { url: '', alt: '' }}
      footnoteExtraStyle={
        {
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        } as React.CSSProperties
      }
      onRenderFootnote={(item, x) => (
        <span className={classNames('parag--sm', x > 0 && 'pr-2')} key={x}>
          {(x === 0 ? 'Frá  ' : 'Til  ') +
            beautifyDate(new Date(item), { short: true })}
        </span>
      )}
    />
  </Link>
)

const SeeAllEvents = ({
  futureEventsTitle,
  pastEventsTitle,
  noFutureEventsTitle
}: Props) => {
  const { futureEvents, pastEvents } = useGetFutureAndPastEvents()
  return (
    <SeeAllContainer>
      <SeeAllHead title={futureEventsTitle} />
      {futureEvents.length > 0 ? (
        <SeeAllResultsUi cards={pastEvents} onRender={renderEventCard} />
      ) : (
        <p>{noFutureEventsTitle}</p>
      )}
      {pastEvents.length > 0 && (
        <div className='mt-6'>
          <SeeAllHead title={pastEventsTitle} />
          <SeeAllResultsUi cards={pastEvents} onRender={renderEventCard} />
        </div>
      )}
    </SeeAllContainer>
  )
}

export default SeeAllEvents
