import React from 'react'
import SearchResults from '@root/src/components/site/SearchResults/SearchResults'
import Chart from '@cmp/site/Chart'
import { SeeAllNews, SeeAllEvents } from '@cmp/site/SeeAll'
import { NewsSlider, EventSlider } from '@cmp/site/CardSlider'

type SystemProps = { programName: string; options: any[] }

const System = ({ programName, options }: SystemProps) => {
  const programs: { [key: string]: React.ElementType } = {
    SearchResults,
    Chart,
    SeeAllNews,
    SeeAllEvents,
    NewsSlider,
    EventSlider
  }
  if (programs[programName]) {
    const Cmp = programs[programName]
    return <Cmp {...options} />
  } else {
    return <div>Unhandled {programName}</div>
  }
}

export default System
