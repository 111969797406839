import React, { useState } from 'react'
import useGetNews from '@src/hooks/useGetNews'
import {
  SeeAllContainer,
  SeeAllHead,
  SeeAllResultsUi,
  Card,
  SearchFiltering
} from '@islandsstofa/react'
import { beautifyDate } from '@islandsstofa/react/src/utils/date'
import { Link } from '@kogk/gatsby-theme-base'

interface Props {
  title: string
}

const SeeAllNews = ({ title }: Props) => {
  const [nameQuery, setNameQuery] = useState('')

  return (
    <SeeAllContainer>
      <SeeAllHead title={title} />
      <SearchFiltering onChange={setNameQuery} label='' placeholder='Sláðu inn leitarorð...' />
      <Results nameQuery={nameQuery} />
    </SeeAllContainer>
  )
}

const Results = ({ nameQuery }: { nameQuery: string }) => {
  const news = useGetNews()

  const filteredNews = news.filter(n =>
    n.name.text.toLowerCase().includes(nameQuery.toLowerCase())
  )

  return (
    <SeeAllResultsUi
      cards={filteredNews}
      onRender={card => (
        <Link key={card.url} to={card.url}>
          <Card
            containerClass='card--see-all--three-col mr-2 mb-2'
            card={card}
            image={card.image}
            onRenderFootnote={(item: string, x: number) => (
              <span className='parag--sm' key={x}>
                {beautifyDate(new Date(item))}
              </span>
            )}
          />
        </Link>
      )}
    />
  )
}

export default SeeAllNews
