import React from 'react'
import {
  InformationBanner as InfoBanner,
  InformationStatisticType
} from '@islandsstofa/react'

const InformationBanner: React.FC<{
  items: { title: string; statistics: InformationStatisticType[] }[]
}> = ({ items }) => {
  if (!items) {
    return null
  }
  return <InfoBanner items={items} />
}

export default InformationBanner
