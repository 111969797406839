import System from '@cmp/sections/System'
import Staff from '@root/src/components/sections/Staff'
import BoardList from '@root/src/components/sections/BoardList'
import InformationBanner from '@cmp/sections/InformationBanner/InformationBanner'
import Embed from '@root/src/components/sections/Embed'
import {
  Feature,
  RichText,
  HeroHeader,
  HeroMedia,
  SEO,
  SEOProps,
  ImageGrid,
  SlimFeature,
  HeroMediaType,
  VideoSection,
  VideoSectionProps,
  HeroCinematic,
  HeroCinematicProps
} from '@islandsstofa/react'
import Tabs from '@cmp/sections/Tabs'
import Img from 'gatsby-image'
import React from 'react'

export default {
  PrismicPageBodyTabs: {
    component: Tabs,
    dataToProps(data: any) {
      return {
        tabs: data.items.map((tab: any) => {
          return {
            title: tab.tab_title,
            content: tab.tab_content
          }
        })
      }
    }
  },

  PrismicPageBodyInformationBanner: {
    component: InformationBanner,
    dataToProps(data: any) {
      const items = data.items.map((item: any) => {
        return {
          title: item.group_name,
          statistics: [
            { title: item.statistic_one_name, value: item.statistic_one_value },
            { title: item.statistic_two_name, value: item.statistic_two_value }
          ]
        }
      })

      return { items }
    }
  },

  PrismicPageBodySlimFeature: {
    component: SlimFeature,
    dataToProps(data: any) {
      const {
        primary: {
          text: { html },
          image,
          link_text,
          link
        }
      } = data
      return {
        html,
        image: {
          alt: image?.alt,
          url: image?.fluid.src
        },
        link: {
          text: link_text,
          url: link?.url
        }
      }
    }
  },
  PrismicPageBodyVideoSection: {
    component: VideoSection,
    dataToProps: (data: any): VideoSectionProps => {
      console.log(data)
      return {
        url: data.primary.video.url,
        description: data.primary.description?.html,
        caption: data.primary.video_caption,
        autoplay: data.primary.autoplay,
        onRenderImage: data.primary.video_thumbnail
          ? () => (
              <img
                src={data.primary.video_thumbnail.url}
                alt={data.primary.video_thumbnail.alt}
              />
            )
          : undefined
      }
    }
  },

  PrismicPageBodySeo: {
    component: SEO,
    dataToProps: (data: any): SEOProps => ({
      title: data.primary.override_title,
      description: data.primary.override_description,
      image: data.primary.override_image,
      keywords: data.primary.override_keywords
    })
  },

  PrismicPageBodyBoardList: {
    component: BoardList,
    dataToProps(data: any) {
      return {
        title: data.primary.boardTitle,
        boardMembers: data.items
      }
    }
  },

  PrismicPageBodyStaff: {
    component: Staff,
    dataToProps(data: any) {
      return {
        staffMembers: data.items,
        title: data.primary.staffTitle
      }
    }
  },

  PrismicPageBodyAllPartners: {
    component: ImageGrid,
    dataToProps(data: any) {
      const result = {
        headline: data.primary.headline.text,
        elements: data.items.map((item: any) => ({
          logo: item.logo,
          name: item.name,
          url: item.website_url.url
        }))
      }
      return { ...result }
    }
  },

  PrismicPageBodyRichText: {
    component: RichText,
    dataToProps(data: any) {
      return {
        html: data.primary.rich_text.html
      }
    }
  },

  PrismicPageBodyHeroMedia: {
    component: HeroMedia,
    dataToProps(data: any): HeroMediaType {
      const {
        primary: {
          caption,
          single_image: singleImage,
          video,
          firstonpage: firstOnPage
        },
        items: gallery
      } = data

      return {
        caption,
        singleImage,
        video,
        gallery,
        firstOnPage,
        onRenderImageComponent: () => (
          <Img
            className='image-rounded'
            fluid={singleImage.fluid}
            alt={singleImage.alt}
          />
        )
      }
    }
  },

  PrismicPageBodyFeature: {
    component: Feature,
    dataToProps(data: any) {
      const {
        primary: {
          text: { html },
          image,
          image_on_right: imageOnRight
        }
      } = data

      return {
        html,
        image,
        imageOnRight
      }
    }
  },

  PrismicPageBodySystem: {
    component: System,
    dataToProps(data: any) {
      return {
        programName: data.primary.program,
        options: data.items
          .filter(
            ({ parameter, value }: { parameter: string; value: string }) =>
              parameter && value !== null
          )
          .reduce((acc: any, item: { value: string; parameter: string }) => {
            acc[item.parameter] = item.value
            return acc
          }, {})
      }
    }
  },

  PrismicPageBodyHeroHeader: {
    component: HeroHeader,
    dataToProps(data: any) {
      const {
        primary: {
          text: { html },
          image
        }
      } = data

      return {
        html,
        image,
        onRenderImage: () => <Img fluid={image.fluid} alt={image.alt} />
      }
    }
  },
  PrismicPageBodyHeroCinematic: {
    component: HeroCinematic,
    dataToProps: (data: any): HeroCinematicProps => ({
      headline: data.primary.headline,
      text: data.primary.text,
      image: data.primary.image,
      videos: data.items.map((item: any) => item.video)
    })
  },
  PrismicPageBodyEmbed: {
    component: Embed,
    dataToProps(data: any) {
      return {
        code: data.primary.embed_code.text,
        lessMargin: data.primary.less_margin,
        description: data.primary.description
      }
    }
  }
}
