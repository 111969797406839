import React from 'react'
import { graphql } from 'gatsby'
import { usePreview, makeSlices } from '@kogk/gatsby-theme-prismic'
import '@src/data/prismic-fragments/page'
import MainLayout from '@cmp/layout/Main'
import sliceMapping from '@src/data/prismic-slice-mapping/page-slices'
import { GlobalDataProvider } from '@src/data/global-data'

const Slices = makeSlices(sliceMapping)

type PageProps = { data: { staticData: any } }

const Page = ({ data: staticData }: PageProps) => {
  const data = usePreview(staticData)

  const language = data.prismicPage.lang.substring(0, 2)

  return (
    <GlobalDataProvider language={language}>
      <MainLayout>
        <article className='page-wrap'>
          <Slices body={data.prismicPage.data.body} />
        </article>
      </MainLayout>
    </GlobalDataProvider>
  )
}

export default Page

export const query = graphql`
  query genericPageQuery($prismicId: ID!) {
    prismicPage(prismicId: { eq: $prismicId }) {
      ...prismicPageFragmentFull
    }
  }
`
