import React from 'react'
import cn from 'classnames'

export type EmbedProps = {
  lessMargin?: boolean
  code: string
  description?: {
    html?: string
  }
}

const Embed = ({ lessMargin = false, code, description }: EmbedProps) => {
  return (
    <div
      className={cn('container', {
        'page-slice-spacer': !lessMargin
      })}
    >
      <div className='article col-lg-8 offset-lg-2 p-0'>
        {description && description.html && (
          <div
            className='mb-3'
            dangerouslySetInnerHTML={{ __html: description?.html }}
          />
        )}
        <div
          className='d-flex justify-content-center'
          dangerouslySetInnerHTML={{ __html: code }}
        />
      </div>
    </div>
  )
}

export default Embed
